import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron02,
  LWrap,
  CBtnList,
  CBreadCrumb,
} from '../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron02
        size="small"
        data={{
          title: {
            main: <>メールマガジンのご案内</>,
            sub: <></>,
          },
        }}
      ></CJumbotron02>

      <section className="u_mtMedium u_mbMedium">
        <LWrap>
          <p className="c_sectLead u_mb25">
            ロイヤルパークホテルでは、購読無料のメールマガジンを発行しております。
            <br />
            新しい宿泊プランをはじめ、レストランの季節メニュー、イベント情報など最新ニュースをお知らせいたします。
          </p>
          <ul className="c_noteList u_tac_pc u_mb60_pc u_mb40_sp">
            <li>
              購読料は無料ですが、メール受信に関する通信料はお客様のご負担となりますので、あらかじめご了承ください。
            </li>
            <li>
              メールアドレスの変更やご登録の解除は、配信済みのメールマガジンより設定いただけます。
            </li>
          </ul>

          <CBtnList
            data={[
              {
                label: 'メールマガジン新規登録のご案内',
                color: 'borderBlack',
                link: {
                  href: 'https://lb.benchmarkemail.com//listbuilder/signupnew?IkfHTmyPVq8noc5PSgLM%252FP5pwVnAjsSIju%252FK0BlUN0LtO5iNRn8gS8X4lBTFzgEtozrTjFZYUg4%253D',
                },
              },
            ]}
          />
        </LWrap>
      </section>

      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
